<template>
  <div class="row ">
    <div class="col-md-12 login-holder">
      <div class="login-box form-card">
        <img src="../assets/logo.png" alt="Logo" width="512" height="120" class="img-fluid logo" />
        <form @submit.prevent="login">
          <div class="form-group">
              <label>Email</label>
            <input type="email" class="form-control" placeholder="Enter your email" required ref="email" />
          </div>
          <div class="form-group">
             <label>Password</label>
            <input :type="showPass ? 'text' : 'password'" class="form-control"  placeholder="Enter your password" ref="password" required minlength="3" />
            <div class="login-icon icon-password">

              <svg @click="showPass = true" v-if="!showPass" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 10.5C4.53705 14.1667 7.37039 16 10.5 16C13.6296 16 16.4629 14.1667 19 10.5" stroke="#373F44" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.5 13.423L2.5 15.5" stroke="#373F44" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.5 13.423L18.5 15.5" stroke="#373F44" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 16L13.5 18.5" stroke="#373F44" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.5 16L7.5 18.5" stroke="#373F44" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              <svg   @click="showPass = false" v-else xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16"><g class="a" transform="translate(0 -74.667)"><g transform="translate(0 74.667)"><path d="M173.889,170.667a3.222,3.222,0,1,0,3.222,3.222A3.224,3.224,0,0,0,173.889,170.667Z" transform="translate(-162.389 -165.889)"/><path d="M11.5,74.667a12.372,12.372,0,0,0-11.5,8,12.372,12.372,0,0,0,11.5,8,12.367,12.367,0,0,0,11.5-8A12.367,12.367,0,0,0,11.5,74.667ZM11.5,88a5.334,5.334,0,1,1,5.227-5.333A5.283,5.283,0,0,1,11.5,88Z" transform="translate(0 -74.667)"/></g></g></svg>
            
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-login btn-block">LogIn</button>
          </div>
          <div class="form-group">
            <router-link :to="{name: 'ForgotPassword'}" class="btn  mt-3 text-link mx-auto d-block f14">
              Forgot Password
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      showPass: false
    }
  },
  methods: {
    login(){
      let email = this.$refs.email.value
      let password = this.$refs.password.value
      if(email.length > 0 && password.length > 0){
        this.$axios.post('login', {email, password})
          .then(res => {
            localStorage.setItem('empanelment-user-name', res.data.data.name);
            localStorage.setItem('empanelment-user-id', res.data.data.user_id);
            localStorage.setItem('empanelment-user-email', res.data.data.email);
            localStorage.setItem('empanelment-crm-token', res.data.data.token);
            localStorage.setItem('empanelment-crm-role_id', res.data.data.role_id);
            localStorage.setItem('empanelment-crm-role_name', res.data.data.role_name);
            this.$router.replace('/');
          })
          .catch(err => {
            swal({
              title: "Login Failed",
              text: err.response.data.message,
              icon: "error"
            })
          })
      }else{
        swal({
          title: "Please enter Email and Password",
          icon: "info"
        })
      }
    }
  }
}
</script>
<style scoped>
  svg{
   
  }
  
</style>