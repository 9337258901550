<template>
    <div class="">
        <!-- <div class="inner-header  ">
            <nav class="navbar fixed-top bg-white animate__animated animate__slideInRight">
                <a @click="$router.go(-1)" class="back-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.368" height="16.365" viewBox="0 0 17.368 16.365">
                        <g id="Group_38360" data-name="Group 38360" transform="translate(-27.632 -34.814)" opacity="1">
                            <line id="Line_124" data-name="Line 124" x2="16" transform="translate(29 43)" fill="none"
                                stroke="#000" stroke-width="2" />
                            <path id="Path_51451" data-name="Path 51451" d="M.033,0l-7,7.425L.033,15"
                                transform="translate(35.967 35.5)" fill="none" stroke="#000" stroke-width="2" />
                        </g>
                    </svg>
                </a>
                <a href="javascript:void(0);" class="link-title center">test New Entry</a>
            </nav>
        </div> -->
        <div class=" bg-white animate__animated animate__slideInRight">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12 public-card-wrapper p-0">
                          <div class="public-header">
                            <span class="main-title">Become a Part of<br><span class="text-orange">India’s</span> Largest Broker<br>Netwok!</span>
                            <img src="../assets/connected-art.svg" alt="Logo" class="img-fluid " />
                          </div>
                        <div class="card details-card form-card">
                            <div class="card-body mt-3">
                                <div class="row">
                                  <div class="col-12">
                                    <b-form @submit.prevent="AddNewbroker">
                                      <b-form-group>
                                        <h3 class="form-heading">Tell us about your firm</h3>
                                      </b-form-group>
                                      <b-form-group>
                                        <label>Your Firm's Name<span class="text-danger">*</span></label>
                                        <b-form-input type="text" v-model="Addbroker.firm_name" required></b-form-input>
                                    </b-form-group>
                                      <b-form-group>
                                        <label>Email<span class="text-danger">*</span></label>
                                        <b-form-input type="email" v-model="Addbroker.email" required></b-form-input>
                                    </b-form-group>
                                      <b-form-group>
                                        <label>Contact Number<span class="text-danger">*</span></label>
                                        <b-form-input type="number" v-model="Addbroker.contact_number" required></b-form-input>
                                    </b-form-group>
                                      <b-form-group>
                                        <label>Your Name<span class="text-danger">*</span></label>
                                        <b-form-input type="text" v-model="Addbroker.name" required></b-form-input>
                                      </b-form-group>
                                    <b-form-group>
                                      <label> Your City <span class="text-danger">*</span></label>
                                        <b-form-select v-model="Addbroker.city_id" @change="fetchRegion(Addbroker.city_id)" :options="cityList" required>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="filterPanel">
                                      <label>Your Region<span class="text-danger">*</span></label>
                                        <b-form-group >
                                          <multiselect v-model="Addbroker.region" placeholder="Select Region" @input="fetchLocality()" :searchable="true" label="text" track-by="value" :options="regionList" :multiple="true" :taggable="true"></multiselect>
                                        </b-form-group>
                                    </b-form-group>
                                    <b-form-group>
                                      <label>Your Locality<span class="text-danger">*</span></label>
                                        <b-form-select v-model="Addbroker.primary_locality_id" :options="localityList" required>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group>
                                      <label>Rera(Optional)</label>
                                       <b-form-input type="text" v-model="Addbroker.rera_id"></b-form-input>
                                    </b-form-group>
                                    <b-form-group>
                                      <label>About<span class="text-danger">*</span></label>
                                        <b-form-textarea v-model="Addbroker.about" required rows="3" max-rows="6">
                                        </b-form-textarea>
                                    </b-form-group>
                                    <b-form-group>
                                      <label>Firm's Address<span class="text-danger">*</span></label>
                                        <b-form-textarea v-model="Addbroker.address" required rows="3" max-rows="6">
                                        </b-form-textarea>
                                    </b-form-group>
                                    <b-form-group class="mb-0">
                                        <b-button type="submit" variant="primary" block>Submit</b-button>
                                    </b-form-group>
                                  </b-form>
                                  </div>
                                </div>
                                <p class="terms">By clicking ‘Submit’ you agree to our</p>
                                <a href="https://www.propertypistol.com/privacy-policy" class="text-link-black ">Terms and Conditions</a>
                                 <img src="../assets/logo.png" alt="Logo" class="img-fluid foot-logo" />
                                <div class="copyright">© 2022 PropertyPistol Syndicate</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {decodeInvitationBrokerParams} from '@/assets/scripts/utility.js';
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  name: 'InviteForm',
  data() {
    return {
      localityList: [],
      cityList: [],
      regionList: [],
      Addbroker: {
        uuid: null,
        name: null,
        email: null,
        contact_number: null,
        firm_name: null,
        primary_locality_id: null,
        rera_id: null,
        region: [],
        about: null,
        address: null,
        city_id: null,
      },
    }
  },
  created () {
    localStorage.setItem('is_public', 'public');
    this.getCity()
  },
  methods: {
    getCity() {
      this.$store.dispatch('setLoading', true);
      this.$axios.get(`brokers/city_data`).then((res) => {
        this.$store.dispatch('setLoading', false);
        this.cityList = []
        res.data.cities.forEach((item,index) => {
          this.cityList.push({value: item.id, text: item.name})
        });
      });
    },
    fetchRegion(row) {
      let queryP = {}
      queryP['city_id'] = row
      this.$store.dispatch('setLoading', true);
      this.$axios.get(`brokers/${row}/regions`, { params: queryP }).then((res) => {
        this.$store.dispatch('setLoading', false);
        this.regionList = []
        res.data.regions_data.forEach((item,index) => {
          this.regionList.push({value: item.id, text: item.name})
        });
      });
    },
    fetchLocality() {
      let queryP = {}
      let {length : l, [l - 1] : b} = this.Addbroker.region;
      if (this.Addbroker.region.length > 0) {
        queryP['region_id'] = b.value
        this.$store.dispatch('setLoading', true);
        this.$axios.get(`brokers/${b.value}/localities`, { params: queryP }).then((res) => {
          this.localityList = []
          this.$store.dispatch('setLoading', false);
          res.data.localities.forEach((item,index) => {
            this.localityList.push({value: item.id, text: item.name})
          });
          this.localityList.unshift({value: null, text: 'Select Locality', disabled: true})
        });
      }
    },
    AddNewbroker() {
      let apiParams = {}
      apiParams = decodeInvitationBrokerParams(this.Addbroker);
      if (Object.keys(apiParams).length > 0) {
        this.$store.dispatch('setLoading', true);
        localStorage.setItem('is_public', 'public');
        this.$axios
        .post(`brokers/invite/${this.$route.params.uuid}`, apiParams)
        .then((res) => {
          swal({
            title: "Created!",
            icon: "success",
          }).then(
            localStorage.removeItem('is_public'),
            this.$router.replace({ name: "Thanks" }),
            this.$store.dispatch('setLoading', false));
        })
        .catch((err) => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error",
            });
            this.$store.dispatch('setLoading', false);
          } else {
            this.$store.dispatch('setLoading', false);
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
            });
          }
        });
      }
      }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
  .public-card-wrapper {
    min-height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
  }

  .public-header {
    background: linear-gradient(180deg, rgba(255, 190, 157, 0) 0%, rgba(255, 190, 157, 0.5) 100%);
    border-radius: 0px 0px 20px 20px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    img {
      margin-top: 20px;
    }
    .main-title {
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      letter-spacing: 0.15px;
      color: #373F44;
       .text-orange {
      color:#233445;
    }
    }
   
  }
.form-heading {
  font-weight: 500;
  font-size: 16px;
  color: #373F44;
}
  .success-message {
    text-align: center;
    margin-top: 30px;
    padding: 20px;
    background: #fff;

    .heading {
      font-size: 16px;
      letter-spacing: 0.15px;
      color: #373F44;
      display: block;
    }

    .message {
      font-size: 18px;
      letter-spacing: 0.15px;
      color: #373F44;
      display: block;
    }

    p {
      margin-top: 100px;
      margin-bottom: 30px;
      font-weight: 300;
    }

    .gplay {
      border: 1px solid #000000;
      border-radius: 6px;
      margin-right: 15px;
    }
  }

  .copyright {
    margin-top: auto;
    margin-bottom:0;
    color: #373F44;
    opacity: 0.5;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }
  .terms{
    color: #373F44;
    opacity: 0.5;
    font-size: 12px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
  }
  .text-link-black {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #373F44;
    display: table;
    margin:auto;
  }
  .foot-logo{
    margin: 80px auto 20px;
    width: 180px;
    display: table;
  }
</style>