<template>
    <div>
        <div class="inner-page animate__animated animate__slideInRight">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="search-holder mt-30 search-holder-list">
                            <div class="input-group" style="margin-top: -10px;">
                                <input type="text" placeholder="Search by broker name, firm name or email"
                                  @input="(e) => (Filter = e.target.value)" :value="Filter" class="search form-control"
                                  @keyup.enter="FilterData" />
                                  <svg v-if="Filter" @click="resetFilter" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001"
                                    style="enable-background: new 0 0 512.001 512.001" xml:space="preserve" width="14" height="14"
                                    class="close">
                                    <g>
                                      <g>
                                        <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                                        L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                                        c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                                        l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                                        L284.286,256.002z" />
                                      </g>
                                    </g>
                                  </svg>
                                <div class="input-group-append align-fix" id="button-addon4">
                                      <button class="btn btn-search" :disabled="!Filter" @click="FilterData" type="button">
                                          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 451 451"
                                            style="enable-background: new 0 0 451 451" xml:space="preserve">
                                            <g>
                                              <path d="M447.05,428l-109.6-109.6c29.4-33.8,47.2-77.9,47.2-126.1C384.65,86.2,298.35,0,192.35,0C86.25,0,0.05,86.3,0.05,192.3
                                                    s86.3,192.3,192.3,192.3c48.2,0,92.3-17.8,126.1-47.2L428.05,447c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4
                                                    C452.25,441.8,452.25,433.2,447.05,428z M26.95,192.3c0-91.2,74.2-165.3,165.3-165.3c91.2,0,165.3,74.2,165.3,165.3
                                                    s-74.1,165.4-165.3,165.4C101.15,357.7,26.95,283.5,26.95,192.3z" />
                                            </g>
                                          </svg>
                                     </button>
                                    <button v-b-toggle.sidebar-right class="filter-menu btn btn-filter btn-white" type="button">
                                    <img src="@/assets/filter.svg" alt="" />
                                </button>
                                </div>
                            </div>
                            <span class="result-Text1">{{ pageStart }} - {{ pageEnd }} of {{ total }}</span>
                        </div>
                    </div>
                    <div class="tab-holder card broker-card mt-n3">
                        <b-tabs content-class="mt-2 " justified>
                            <b-tab
                                  v-for="(tab, index) in tabs"
                                  :key="index"
                                  :title="tab.title"
                                  @click="GetBrokers(tab.label)"
                            >
                                <div class="col-12 p-0">
                                    <div class="card enquiry-card">
                                        <b-list-group class="list-group-flush">
                                            <b-list-group-item  v-for ="(item, index) in GetAllBrokers" :key="index">
                                              <router-link :to="{ name: 'Details', params: {  id: item.id }}">
                                                <div class="row">
                                                    <div class="col-6 pr-1">
                                                        <span class="name ellipses">{{item.firm_name}}</span>
                                                        <span class="project-name" v-if="item.locality">{{item.locality}}</span>
                                                    </div>
                                                    <div class="col-6 pl-1 text-right">
                                                      <span class="project-name" v-if="item.created_by">{{item.created_by}}</span>
                                                    </div>
                                                </div>
                                              </router-link>
                                            </b-list-group-item>
                                        </b-list-group>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- Right Menu Sidebar -->
                    <b-sidebar id="sidebar-right" ref="sidebar-right" title="Advance Search" bg-variant="white" backdrop right shadow>
                      <template v-slot="{ hide }">
                      <div class="card form-card sidebar-form-card">
                        <b-form @submit.prevent="AdvancedFilter" @reset="resetAdvancedFilter">
                          <b-form-group>
                            <b-form-input type="text" v-model="AdvancedFilterBroker.name" placeholder="Name"></b-form-input>
                          </b-form-group>
                          <b-form-group>
                            <b-form-input type="text" v-model="AdvancedFilterBroker.firm_name" placeholder="Firm Name"></b-form-input>
                          </b-form-group>
                          <b-form-group>
                            <b-form-input type="email" v-model="AdvancedFilterBroker.email" placeholder="Email Address"></b-form-input>
                          </b-form-group>
                          <b-form-group>
                            <b-form-input type="text" v-model="AdvancedFilterBroker.contact_number" placeholder="Contact Number"></b-form-input>
                          </b-form-group>
                          <b-form-group>
                            <multiselect v-model="AdvancedFilterBroker.localities" class="filterPanel" :searchable="true"  placeholder="Locality" label="text" track-by="value" :options="LocalitiesList" :multiple="true" :taggable="true"></multiselect>
                          </b-form-group>
                          <b-form-group>
                            <multiselect v-model="AdvancedFilterBroker.executive_id" class="filterPanel" :searchable="true"  placeholder="Executive" label="text" track-by="value" :options="executiveList" :taggable="true"></multiselect>
                          </b-form-group>
                          <b-form-group>
                            <label>Emp date From</label>
                            <b-form-input type="date" v-model="AdvancedFilterBroker.empanelment_date_from"></b-form-input>
                          </b-form-group>
                          <b-form-group>
                            <label>Emp date To</label>
                            <b-form-input type="date" v-model="AdvancedFilterBroker.empanelment_date_upto"></b-form-input>
                          </b-form-group>
                          <b-form-group class="pl-3 checkbox-fix">
                            <b-form-checkbox
                              v-model="AdvancedFilterBroker.referer_broker"
                              name="Active ?"
                              value="true"
                              unchecked-value="false"
                            >
                              Referal Broker?
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group class="pl-3 mt-n2 checkbox-fix">
                            <b-form-checkbox
                              v-model="AdvancedFilterBroker.is_affiliate"
                              name="Active ?"
                              value="true"
                              unchecked-value="false"
                            >
                              Is Affiliate ?
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group class="btn-fixed">
                          <b-button variant="primary" type="submit" block @click="hide">Search</b-button>
                          <b-button variant="primary-outline" block type="reset">Reset</b-button>
                        </b-form-group>
                        </b-form>
                      </div>
                      </template>
                    </b-sidebar>
                </div>
                <paginate  v-if="totalPages > 1" v-model="pageNo" :page-count="totalPages" :click-handler="goToPage"
                  :prev-text="'Prev'" :next-text="'Next'" :prev-class="'page-item'" :prev-link-class="'page-link'"
                  :next-class="'page-item'" :next-link-class="'page-link'" :container-class="'pagination justify-content-center'"
                  :page-class="'page-item'" :page-link-class="'page-link'">
                </paginate>
                <router-link :to="{ name: 'New' }" class="float-btn"><img src="@/assets/plus.svg" alt=""></router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { paramsFromQuery, prepareBrokerSearchParams } from "@/assets/scripts/utility.js";
export default {
  name: "Home",
  components: {
    Multiselect
  },
  data() {
    return {
      tabs: [
        { label: "pending", title: "Pending" },
        { label: "completed", title: "My Cps" }
      ],
      GetAllBrokers: [],
      advSearchParams: [],
      tabStatus: null,
      total: 0,
      perPage: 0,
      Filter: this.$route.query.search_query,
      AdvancedFilterBroker: {
        name: null,
        firm_name: null,
        contact_number: null,
        email: null,
        rm_ids: [],
        localities: [],
        executive_id: null,
        empanelment_date_from: null,
        empanelment_date_upto: null,
        referer_broker: false,
        is_affiliate: false,
      },
    }
  },
  computed: {
    activeTab() {
      return this.$store.getters.sDefaults;
    },
    executiveList() {
      return this.$store.getters.fieldExecutiveMasterList;
    },
    rmList() {
      return this.$store.getters.rmMasterList;
    },
    LocalitiesList() {
      return this.$store.getters.LocalitiesMasterList;
    },
    totalPages() {
      if (this.total === 0 || this.perPage === 0) {
        return 0;
      } else {
        return Math.ceil(this.total / this.perPage);
      }
    },
    pageNo: {
      get() {
        let pageNo = this.$route.query.pageNo || 1;
        return parseInt(pageNo);
      },
      set() {
        true;
      },
    },
    pageStart() {
      if (this.total === 0) {
        return 0;
      }
      return Math.max(0, (this.pageNo - 1) * this.perPage + 1);
    },
    pageEnd() {
      if (this.total === 0) {
        return 0;
      }
      return Math.min(this.total, this.pageNo * this.perPage);
    }
  },
  created() {
    let defT = this.activeTab;
    let defTObj = this.tabs.find((k) => k.label === defT.tab);
    this.GetBrokers(defTObj.label);
  },
  watch: {
    $route(to, from) {
      this.GetBrokers(this.tabStatus);
    },
  },
  methods: {
    GetBrokers(row) {
      let queryP = {};
      this.tabStatus = row
      queryP['status'] = row;
      if (queryP.status === undefined) {
        queryP.status = 'pending'
      }
      if(this.$route.query.is_advanced_search){
        let searchParams = this.$route.query
        for(let key in searchParams){
          queryP[key] = searchParams[key];
        }
        this.advSearchParams = queryP
      }
      if (this.advSearchParams.length > 0){
        paramsFromQuery(queryP, this.advSearchParams);
      }
      else{
        paramsFromQuery(queryP, this.$route.query);
      }
      let url = `brokers`;
      this.$axios
        .get(url, { params: queryP})
        .then((res) => {
          this.GetAllBrokers = res.data.brokers
          this.perPage = res.data.per_page;
          this.total = res.data.count;
        })
        .catch((err) => console.log(err));
    },
    resetFilter() {
      let rQ = this.$route.query;
      this.Filter = "";
      if (rQ.search_query) {
        let {
          search_query,
          ...newQ
        } = rQ;
        this.$router.replace({
          query: newQ
        });
      }
    },
    FilterData() {
      if (this.Filter) {
        let newQ = {
          ...this.$route.query,
          search_query: this.Filter
        };
        this.$router.replace({
          query: newQ
        });
      }
    },
    AdvancedFilter() {
      let queryp = {}
      let searchParams = prepareBrokerSearchParams(this.AdvancedFilterBroker);
      let newQ = {...searchParams, is_advanced_search: true};
      this.$router.replace({query: newQ});
    },
    resetAdvancedFilter() {
      this.AdvancedFilterBroker = {}
      let rQ = this.$route.query
      if (rQ.is_advanced_search) {
        this.$router.replace({
          query: {}
        });
      }
    },
    goToPage(pageNo) {
      let newQ = {
        ...this.$route.query,
        pageNo
      };
      this.$router.replace({
        query: newQ
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.tab-holder .nav-tabs {
    border: none;
    margin-top: 25px;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>