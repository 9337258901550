<template>
<div class="row ">
  <div class="col-md-12 login-holder">
    <div class="login-box form-card">
      <div>
        <img src="../../assets/logo.png" alt="PropertyPistol Syndicate Logo" />
        <form @submit.prevent="resetPassword">
            <b-form-group>
              <b-form-input type="email" v-model="email" placeholder="Email"></b-form-input>
            </b-form-group>
          <div class="form-group">
            <button  :disabled="email === ''" type="submit" class="btn btn-primary mt-5 btn-block">
              Send Reset Link
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
    name:"ForgotPassword",
    data(){
    return{
      email: ''
    }
  },
  methods: {
    resetPassword(){
      let email = this.email
      let forgotUrl = '/forgot_password'
      this.$axios.put(forgotUrl, {email: email, type: 'empanelment'})
        .then(res => {
          swal({
            title: "Reset Link Sent to Your Registered e-mail",
            text: 'Sent!',
            icon: "success"
          })
          this.$router.replace('/login');
        })
      .catch(err => {
        if (err.response.status === 500) {
          swal({
            title: "Server Error!",
            icon: "error"
          });
        } else {
          swal({
            title: "Error",
            text: err.response.data.message,
            icon: "error"
          });
        }
      })
    }
  },
};
</script>
<style scoped>
.login-holder {
  height: 100vh;
  position: relative;
  background: #fff;
}
.login-box img {
  margin: 0 auto 100px;
  display: table;
  max-width: 190px;
}
.f14{
  font-size:14px ;
}
</style>