<template>
  <div class="login-holder">
    <div class="login-box">
      <div>
        <img src="@/assets/logo.png" alt="PropertyPistol Syndicate Logo" />
        <form @submit.prevent="updatePassword">
          <div class="form-group">
            <input
              type="password"
              class="form-control p-4"
              placeholder="New Password"
              ref="password"
              required
              v-model="password"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control p-4"
              placeholder="Confirm Password"
              ref="password"
              required
              v-model="password_confirmation"
            />
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary mt-5 btn-block">
              Reset Password
            </button>
          </div>
          <router-link to="/" class="btn  mt-3 text-link mx-auto d-block f14">
            Login
          </router-link>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      password: null,
      password_confirmation: null,
      reset_password_token: null
    }
  },
  created(){
    this.reset_password_token = this.$route.query.reset_password_token
  },
  methods: {
    updatePassword(){
      let params = {}
      params["password"] = this.password
      params["password_confirmation"] = this.password_confirmation
      params["token"] = this.reset_password_token
      let forgotUrl = '/users/password'
      this.$axios.put(forgotUrl, {password: this.password, password_confirmation: this.password_confirmation, reset_password_token: this.reset_password_token})
        .then(res => {
          swal({
            title: "Updated!",
            icon: "success"
          });
          this.$router.replace('/login');
        })
        .catch(err => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        })
    }
  }
}
</script>
<style scoped>
.login-holder {
  height: 100vh;
  position: relative;
  background: #fff;
}
.login-box img {
  margin: 0 auto 100px;
  display: table;
  max-width: 190px;
}
.f14{
  font-size:14px ;
}
</style>